<template lang="pug">
.main    
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
            
    .container-xxl
      .row.no-gutters
          .col-12
            router-view(name="main").router-view-wrap  
    
</template>

<script>
export default {
  name: 'SubmenuScout',
  props: {
    title: String
  },
  methods: {
    changed: function(event) {
      this.$store.commit('change', event)
    }
  },
  computed: {
    doc: {
        get () {
            return this.$store.state.project.docId
        },
        set (value) {
            this.$store.commit('change', value)
        }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    
    @import '@/_vars.scss';
    .sticky-top {
        background-color:#EBF1FA;
    }
    .submenu {       
        padding: $pad $pad 0 $pad;
        height: 300px;
        background-image: linear-gradient(180deg, #EBF1FA 35%, $light 96%);
    }

   
</style>
